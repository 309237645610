
$(document).ready(function() {
	console.log('lazy jquery');
	  
	$('.lazy').lazy();


	$('.filter-select').on('click', function() {
		$(this).toggleClass('sortreversorder');
		var $wrapper = $('.books');
		this.value = $( "#filter option:selected" ).val();
		// console.log(this.value);
		if ($(".sortreversorder")[0]){ 
			//reg
			if(this.value == 'fb'){
				$wrapper.find('.books__item').sort(function (a, b) {
					return +a.dataset.fb  - +b.dataset.fb;
				})
				.appendTo( $wrapper);
			}
			if(this.value == 'fa'){
				$wrapper.find('.books__item').sort(function (a, b) {
					return +a.dataset.fa - +b.dataset.fa;
				})
				.appendTo( $wrapper );
			}
			if(this.value == 'fd'){
				$wrapper.find('.books__item').sort(function (a, b) {
					return +a.dataset.fd - +b.dataset.fd;
				})
				.appendTo( $wrapper );
			}
		}else{
			//no
			if(this.value == 'fb'){
				$wrapper.find('.books__item').sort(function (a, b) {
					return +b.dataset.fb - +a.dataset.fb   ;
				})
				.appendTo( $wrapper);
			}
			if(this.value == 'fa'){
				$wrapper.find('.books__item').sort(function (a, b) {
					return  +b.dataset.fa - +a.dataset.fa ;
				})
				.appendTo( $wrapper );
			}
			if(this.value == 'fd'){
				$wrapper.find('.books__item').sort(function (a, b) {
					return +b.dataset.fd - +a.dataset.fd  ;
				})
				.appendTo( $wrapper );
			}

			
		}
	})

	$('#filter').on('change', function() {
		// alert( this.value );w
		var $wrapper = $('.books');
		if ($(".sortreversorder")[0]){ 
			//reg
			if(this.value == 'fb'){
				$wrapper.find('.books__item').sort(function (a, b) {
					return +a.dataset.fb  - +b.dataset.fb;
				})
				.appendTo( $wrapper);
			}
			if(this.value == 'fa'){
				$wrapper.find('.books__item').sort(function (a, b) {
					return +a.dataset.fa - +b.dataset.fa;
				})
				.appendTo( $wrapper );
			}
			if(this.value == 'fd'){
				$wrapper.find('.books__item').sort(function (a, b) {
					return +a.dataset.fd - +b.dataset.fd;
				})
				.appendTo( $wrapper );
			}
		}else{
			//no
			if(this.value == 'fb'){
				$wrapper.find('.books__item').sort(function (a, b) {
					return +b.dataset.fb - +a.dataset.fb   ;
				})
				.appendTo( $wrapper);
			}
			if(this.value == 'fa'){
				$wrapper.find('.books__item').sort(function (a, b) {
					return  +b.dataset.fa - +a.dataset.fa ;
				})
				.appendTo( $wrapper );
			}
			if(this.value == 'fd'){
				$wrapper.find('.books__item').sort(function (a, b) {
					return +b.dataset.fd - +a.dataset.fd  ;
				})
				.appendTo( $wrapper );
			}
			
		}
	

		
	

	  });

	 
 


})
 
