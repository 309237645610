$(document).ready(function() {

	var dirRtlFlag = false;
	if ($("html").attr('dir') == 'rtl') {
		dirRtlFlag = true;
	}

	$('.hp-slider').slick({
		dots: false,
		infinite: true,
		speed: 700,
		slidesToShow: 6,
		slidesToScroll: 1,
		arrows: true, 
		autoplay: false,
		
		rtl: dirRtlFlag,
		responsive: [{
				breakpoint: 1300,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 993,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 650,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 550,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 450,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	$(document).ready(function() {

		//news slider
		$('.ticker2').easyTicker({
			direction: 'up',
			speed: 'slow',
			interval: 8000,
			height: 'auto',
			visible: 1,
			mousePause: 1
		});
		// end news slider
	
	});

})