$(document).ready(function() {
	function getEqualHeight(h1, h2) {
		if (h1 < h2) {
			$('.content-str__right').css('minHeight', h2);
		} else {
			$('.content-str__left').css('minHeight', h1);
		}
	}
	if($(window).width() >= 992) {
		getEqualHeight($('.content-str__right').outerHeight(true), $('.content-str__left').outerHeight(true));
	}
});